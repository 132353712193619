import React, { Component } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// Expected Props
//  - link
//  - title
//  - children

class InternalLink extends Component {
  render() {
    let currentTheme;

    if (typeof window !== `undefined`) {
      currentTheme = localStorage.theme;
    } else {
      currentTheme = "light";
    }
    const getColor = (themeNow) =>
      themeNow === "light" ? "#3355FF" : "#001681";

    return (
      <AniLink
        cover
        bg={getColor(currentTheme)}
        duration={1.2}
        to={this.props.link}
        title={this.props.title}
      >
        {this.props.children}
      </AniLink>
    );
  }
}

export default InternalLink;
